@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectSelectedOption[data-v-49d565fa] {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  padding:  0 2rem 0 0.5rem;
  margin:  0 0.5rem 0.5rem 0;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #313233;
  background: #EDF7FB;
  border-radius: 15px;
  border: 1px solid #EDF7FB;
}
.MultiSelectSelectedOption[data-v-49d565fa]:hover {
    cursor: pointer;
    text-decoration: line-through;
    border: 1px solid #929699;
}
.MultiSelectSelectedOption[data-v-49d565fa]:focus {
    outline: none;
}
.MultiSelectSelectedOptionDelete[data-v-49d565fa] {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin:  0 0 0 0.5rem;
  cursor: pointer;
}
