@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectSearchBoxWrapper[data-v-7618172e] {
  position: relative;
  margin:  0 0.5rem 0.5rem;
  padding:  0.5rem 0;
  border-bottom: 1px solid #DCE1E5;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e] {
    width: 100%;
    height: 40px;
    padding:  0 0.5rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #929699;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    appearance: none;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox.is-empty[data-v-7618172e] {
      background: #EDF7FB;
      border: 0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox.is-empty[data-v-7618172e]:focus {
        border: 0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e]:focus {
      outline: none;
      border: 1px solid #247BA0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e]::placeholder {
      color: #247BA0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBoxIcon[data-v-7618172e] {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}
